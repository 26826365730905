import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import {
    Devices,
    EditDevice,
    Home,
    CustomerHierarchy,
    StockSummary,
    Audits,
    GroupManagement,
    SerialNumberUpload,
    SimCardActivation,
    NotAuthorised
} from './containers';
import { Nav, SidebarTabs } from './components';
import { AuthProvider, AppProvider, DevicesProvider, AuthConsumer } from './contexts';

const App = () => {
    var preSetFilters = '';

    // Because this is not a component we have had to use a setter and getter to
    // Fake up some state on this object.
    // This should probably be migrated to a componenet at some time in the future.
    function SetFilters(filters) {
        preSetFilters = filters;
        return preSetFilters;
    }

    return (
        <AppProvider>
            <AuthProvider>
                <AuthConsumer>
                    {({ state, actions }) => {
                        if (!actions.isLoggedIn()) {
                            // Trigger a login before app loads
                            actions.login();
                        } else {
                            return (
                                <DevicesProvider authState={state} authActions={actions}>
                                    <BrowserRouter>
                                        <>
                                            <Nav />
                                            <SidebarTabs />
                                            <main>
                                                <Switch>
                                                    <Redirect from="/" exact to="/Home" />
                                                    <Route path="/Home">
                                                        <Home setFilters={SetFilters} />
                                                    </Route>
                                                    <Route path="/Devices" component={Devices} />
                                                    <Route path="/EditDevice/:id" component={EditDevice} />
                                                    <Route path="/CustomerHierarchy" component={CustomerHierarchy} />
                                                    <Route path="/StockSummary/:customerId" component={StockSummary} />
                                                    <Route path="/Audits" component={Audits} />
                                                    <Route path="/SerialValidation" component={SerialNumberUpload} />
                                                    <Route path="/GroupManagement" component={GroupManagement} />
                                                    <Route path="/SimCardActivation" component={SimCardActivation} />
                                                    <Route path="/NotAuthorised" component={NotAuthorised} />
                                                    <Route render={() => <h1>404 not found</h1>} />
                                                </Switch>
                                            </main>
                                        </>
                                    </BrowserRouter>
                                </DevicesProvider>
                            );
                        }
                    }}
                </AuthConsumer>
            </AuthProvider>
        </AppProvider>
    );
};

export default App;
