import React, { useState } from 'react';
import Papa from 'papaparse/papaparse.min.js';
import DataTable from 'react-data-table-component';
import { HttpService } from '../../services';

import '../../styles/components/_Refurbishment.scss';

export const SerialNumberUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [dateOfReturn, setDateOfReturn] = useState('');
    const [boxNumber, setBoxNumber] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [fileName, setFileName] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [showInfo, setShowInfo] = useState(false);

    const [errors, setErrors] = useState({
        file: '',
        dateOfReturn: '',
        boxNumber: ''
    });

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold'
            }
        },
        rows: {
            style: {
                fontSize: '14px'
            }
        }
    };

    const validateFields = () => {
        let fieldErrors = { file: '', dateOfReturn: '', boxNumber: '' };
        let valid = true;

        if (!selectedFile) {
            fieldErrors.file = 'Please select a valid CSV file.';
            valid = false;
        }
        if (!dateOfReturn) {
            fieldErrors.dateOfReturn = 'Please select the return date.';
            valid = false;
        }
        if (!boxNumber) {
            fieldErrors.boxNumber = 'Please enter a box number.';
            valid = false;
        }

        setErrors(fieldErrors);
        return valid;
    };

    const handleFileChange = e => {
        const file = e.target.files[0];
        if (!file) {
            resetState();
            return;
        }

        if (!file.name.toLowerCase().endsWith('.csv') && file.type !== 'text/csv') {
            setMessage('Please upload a valid CSV file.');
            resetState();
            return;
        }

        setSelectedFile(file);
        setFileName(file.name);
        setMessage('');
        setIsDisabled(!dateOfReturn || !boxNumber);

        Papa.parse(file, {
            skipEmptyLines: true,
            complete: results => {
                if (results.data?.length > 0) {
                    const [headerRow, ...dataRows] = results.data;
                    setCsvHeaders(headerRow);
                    setCsvData(dataRows);
                }
            }
        });
    };

    const handleDateChange = e => {
        const newDate = e.target.value;
        setDateOfReturn(newDate);
        setIsDisabled(!selectedFile || !newDate || !boxNumber);
    };

    const handleBoxNumberChange = e => {
        const newBoxNumber = e.target.value;
        setBoxNumber(newBoxNumber);
        setIsDisabled(!selectedFile || !dateOfReturn || !newBoxNumber);
    };

    const handleFileUpload = async () => {
        if (!validateFields()) {
            setMessage('Please fix the errors before uploading.');
            return;
        }

        setIsSubmitting(true);
        setMessage('Uploading file... please wait.');

        try {
            const formData = new FormData();
            formData.append('serialNumberCsvStream', selectedFile);
            formData.append('DateOfReturn', dateOfReturn);
            formData.append('BoxNumber', boxNumber);

            const response = await HttpService.post('/Device/RefurbValidation', formData, {
                responseType: 'blob',
                validateStatus: () => true
            });

            if (response.status === 200) {
                const dlFileName = `refurb-validation-box-${boxNumber}.csv`;
                const blob = new Blob([response.data]);
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', dlFileName);
                document.body.appendChild(link);
                link.click();
                link.remove();

                setMessage('Success! Your validated CSV has downloaded.');
            }
        } catch (error) {
            if (error.response) {
                const errorBlob = new Blob([error.response.data]);
                const errorText = await errorBlob.text();
                setMessage(`Error - ${errorText}`);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const resetState = () => {
        setSelectedFile(null);
        setCsvData([]);
        setCsvHeaders([]);
        setFileName('');
        setDateOfReturn('');
        setBoxNumber('');
        setIsDisabled(true);
        setMessage('');
        setErrors({ file: '', dateOfReturn: '', boxNumber: '' });
    };

    return (
        <div className="serial-number-upload-container">
            <h2 className="serial-number-upload-title">Refurbishment Validation Service</h2>

            {showInfo && (
                <div className="info-sheet">
                    <h3>How to Use</h3>
                    <p>
                        This page is used for checking pre-refurbished devices against their manufactured date to ascertain whether they are
                        still in warranty.
                        <br />
                        <br />
                        1. You must have a CSV file with 3 columns. <br />
                        &emsp; a. 'Refurb'
                        <br />
                        &emsp; b. 'Faulty / Warranty'
                        <br />
                        &emsp; c. 'DOA / Warranty'
                        <br />
                        2. Under each column should denote the serial numbers of the devices.
                        <br />
                        3. Wrap them in a CSV file.
                        <br />
                        4. Enter the date returned.
                        <br />
                        5. Enter the refurbishment box number.
                        <br />
                        6. Click "Upload" to begin refurbishment validation.
                        <br />
                    </p>
                    <p>You will receive a validated CSV to download if the request succeeds. Any errors will display below the buttons.</p>
                </div>
            )}

            <div className="field-row">
                <label className="label">Date Returned:</label>
                <div className="input-column">
                    <input type="date" value={dateOfReturn} onChange={handleDateChange} className="input-field" disabled={isSubmitting} />
                    {errors.dateOfReturn && <span className="error">{errors.dateOfReturn}</span>}
                </div>
            </div>

            <div className="field-row">
                <label className="label">Box Number:</label>
                <div className="input-column">
                    <input
                        type="number"
                        value={boxNumber}
                        onChange={handleBoxNumberChange}
                        className="input-field"
                        disabled={isSubmitting}
                    />
                    {errors.boxNumber && <span className="error">{errors.boxNumber}</span>}
                </div>
            </div>

            <div className="field-row">
                <label className="label">CSV File:</label>
                <div className="input-column">
                    <input type="file" accept=".csv" onChange={handleFileChange} className="input-field" disabled={isSubmitting} />
                    {errors.file && <span className="error">{errors.file}</span>}
                </div>
            </div>

            {fileName && (
                <p className="file-info">
                    <strong>Selected File:</strong> {fileName}
                </p>
            )}

            <div className="button-row">
                <button onClick={handleFileUpload} disabled={isDisabled || isSubmitting} className="upload-button">
                    {isSubmitting ? 'Uploading...' : 'Upload'}
                </button>
                <button onClick={resetState} disabled={isSubmitting} className="reset-button">
                    Reset
                </button>
                <button onClick={() => setShowInfo(!showInfo)} className="info-button">
                    {showInfo ? 'Bye Help' : 'Help'}
                </button>
            </div>

            {message && <p className="message">{message}</p>}

            {csvData.length > 0 && (
                <DataTable
                    title="CSV Preview"
                    columns={csvHeaders.map((header, index) => ({
                        name: header,
                        selector: row => row[index],
                        sortable: false
                    }))}
                    data={csvData}
                    customStyles={customStyles}
                    pagination
                    responsive
                />
            )}
        </div>
    );
};
