import axios from 'axios';
import { getGlobalUserManager } from '../contexts';

const axiosInstance = axios.create();
const MAX_RETRY_COUNT = 1;
let outstandingRequests = 0;
let refreshInProgress = null;

const resetRequest = async error => {
    outstandingRequests--;
    if (outstandingRequests < 0) {
        outstandingRequests = 0;
    }

    if (document.location.pathname !== '/NotAuthorised') {
        if (outstandingRequests === 0) {
            document.body.children.root.lastChild.classList.remove('loading-indicator');
        }
    }

    const { response, config } = error;
    if (!response || response.status !== 401) {
        return Promise.reject(error);
    }

    config._retryCount = config._retryCount || 0;
    if (config._retryCount >= MAX_RETRY_COUNT) {
        return Promise.reject(error);
    }
    config._retryCount += 1;

    try {
        const userManager = getGlobalUserManager();
        if (!userManager) {
            return Promise.reject(error);
        }

        if (refreshInProgress) {
            await refreshInProgress;
        } else {
            refreshInProgress = userManager
                .signinSilent()
                .catch(silentError => {
                    throw silentError;
                })
                .finally(() => {
                    refreshInProgress = null;
                });

            await refreshInProgress;
        }

        const newUser = await userManager.getUser();
        if (!newUser || newUser.expired) {
            await userManager.signinRedirect();
            return Promise.reject(error);
        }

        setAccessToken(newUser);

        config.headers['Authorization'] = `${newUser.token_type} ${newUser.access_token}`;

        return axiosInstance.request(config);
    } catch (silentError) {
        const userManager = getGlobalUserManager();
        if (userManager) {
            userManager.signinRedirect();
        }
        return Promise.reject(silentError);
    }
};

axiosInstance.interceptors.request.use(config => {
    outstandingRequests++;
    if (document.location.pathname !== '/NotAuthorised') {
        document.body.children.root.lastChild.classList.add('loading-indicator');
    }
    return config;
}, resetRequest);

axiosInstance.interceptors.response.use(response => {
    outstandingRequests--;
    if (outstandingRequests === 0) {
        document.body.children.root.lastChild.classList.remove('loading-indicator');
    }
    return response;
}, resetRequest);

let config;
let accessToken;

const get = (url, params) => {
    const headers = getHeaders();
    return axiosInstance.get(`${config.apiUrl}${url}`, { params, headers });
};

const post = (url, data) => {
    const headers = getHeaders();
    return axiosInstance.post(`${config.apiUrl}${url}`, data, { headers });
};

const put = (url, data) => {
    const headers = getHeaders();
    return axiosInstance.put(`${config.apiUrl}${url}`, data, { headers });
};

const del = url => {
    const headers = getHeaders();
    return axiosInstance.delete(`${config.apiUrl}${url}`, { headers });
};

const getHeaders = () => {
    return accessToken ? { Authorization: accessToken } : {};
};

const setAccessToken = user => {
    accessToken = user && !user.expired && `${user.token_type} ${user.access_token}`;
};

const setConfig = newConfig => {
    config = newConfig;
};

const getConfig = () => {
    return config;
};

const HttpService = {
    get,
    post,
    put,
    delete: del,
    setAccessToken,
    setConfig,
    getConfig
};

export { HttpService };
